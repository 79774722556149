import * as commonTypes from "./../ActionTypes";
import { ICommonTypes } from "../../Types/reducerTypes/commonTypes";
import { APP_CONSTANTS_DEFAULTS } from "../../Constants/appConstants";

const initialState: ICommonTypes = {
  pageLoaderVisibility: false,
  showPremiumSearchModal: false,
  setAlertFlowDialog: {
    show: false,
    variantId: null,
  },
  alertModalToggle: false,
  productLoaderId: null,
  variant: {
    variantModal: false,
    chooseRepeatModal: false,
    selectedVariantsModal: false,
  },
  detailedItem: null,
  isItemModalVisible: false,
  isBillContactModalVisible: false,
  isBillSendModalVisible: false,
  isCreateBillModalVisible: false,
  userAccountInfo: {},
  transactionList: {
    startDate: new Date("2021-04-01"),
    endDate: new Date(),
  },
  loginPageInfo: {},
  setOnboardingDomainInfo: {},
  shipmentModalView: false,
  deliveryTimeModalView: false,
  customerPaymentChoiceModalView: false,
  deliveryPartnerDrawerView: false,
  howToShipModalView: false,
  showDomainSuccessfulModal: false,
  storePolicies: [],
  workspaceExpiryModalView: false,
  domainRenewalModalView: false,
  topBanner: {
    visible: false,
    height: 48,
  },
  ecommEliteCallbackModal: {
    view: false,
    planType: null,
  },
  diceLoaderVisibilty: false,
  autoGenerateTextLoader: false,
  searchedSettingData: [],
  searchedQueryID: "",
  themeUpsellModal: false,
  appConstants: APP_CONSTANTS_DEFAULTS,
  ordersOnboardingPopup: false,
};

const commonReducer = (state: any = initialState, action: any): any => {
  switch (action.type) {
    case commonTypes.SET_PAGE_LOADER_VISIBILITY:
      return { ...state, pageLoaderVisibility: action.data };
    case commonTypes.SHOW_PREMIUM_SEARCH_MODAL:
      return { ...state, showPremiumSearchModal: action.data };
    case commonTypes.SAVE_PRODUCT_LOADER_ID:
      return {
        ...state,
        productLoaderId: action.data,
      };
    case commonTypes.SET_ALERT_FLOW_DIALOG:
      return {
        ...state,
        setAlertFlowDialog: {
          show: action.data.show,
          variantId: action.data.variantId,
        },
      };
    case commonTypes.ALERT_MODAL_TOGGLE:
      return { ...state, alertModalToggle: action.data };
    case commonTypes.SAVE_DETAILED_ITEM_BY_ID:
      return {
        ...state,
        detailedItem: action.data,
      };
    case commonTypes.SET_CHOOSE_AND_REPEAT_MODEL_VISIBILITY:
      return {
        ...state,
        variant: {
          variantModal: false,
          chooseRepeatModal: action.data,
          selectedVariantsModal: false,
        },
      };
    case commonTypes.SET_VARIANT_MODEL_VISIBILITY:
      return {
        ...state,
        variant: {
          variantModal: action.data,
          chooseRepeatModal: false,
          selectedVariantsModal: false,
        },
      };
    case commonTypes.SET_SELECTED_VARIANT_MODEL_VISIBILITY:
      return {
        ...state,
        variant: {
          variantModal: false,
          chooseRepeatModal: false,
          selectedVariantsModal: action.data,
        },
      };
    case commonTypes.SET_ADD_PRODUCT_MODAL_VISIBLE:
      return { ...state, isItemModalVisible: action.data };
    case commonTypes.IS_BILL_CONTACT_MODAL_VISIBLE:
      return { ...state, isBillContactModalVisible: action.data };
    case commonTypes.IS_BILL_SEND_MODAL_VISIBLE:
      return { ...state, isBillSendModalVisible: action.data };
    case commonTypes.IS_CREATE_BILL_MODAL_VISIBLE:
      return { ...state, isCreateBillModalVisible: action.data };
    case commonTypes.SET_TRANSACTION_LIST:
      return { ...state, transactionList: action.data };
    case commonTypes.SAVE_USER_ACCOUNT_INFO:
      return { ...state, userAccountInfo: action.data };
    case commonTypes.SET_LOGIN_PAGE_INFO:
      return { ...state, loginPageInfo: action.data };
    case commonTypes.SET_ONBOARDING_DOMAIN_INFO:
      return { ...state, setOnboardingDomainInfo: action.data };
    case commonTypes.TOGGLE_SHIPMENT_DATA_MODAL:
      return { ...state, shipmentModalView: !state.shipmentModalView };
    case commonTypes.TOGGLE_DELIVERY_TIME_MODAL:
      return { ...state, deliveryTimeModalView: action.data };
    case commonTypes.TOGGLE_CUSTOMER_PAYMENT_CHOICE_MODAL:
      return { ...state, customerPaymentChoiceModalView: action.data };
    case commonTypes.TOGGLE_DELIVERY_PARTNER_DRAWER:
      return { ...state, deliveryPartnerDrawerView: action.data };
    case commonTypes.TOGGLE_HOW_TO_SHIP_MODAL:
      return { ...state, howToShipModalView: action.data };
    case commonTypes.SHOW_DOMAIN_SUCCESSFUL_PURCHASE_MODAL:
      return { ...state, showDomainSuccessfulModal: action.data };
    case commonTypes.SAVE_STORE_POLICY_DATA:
      return { ...state, storePolicies: action.data };
    case commonTypes.TOGGLE_WORKSPACE_EXPIRY_MODAL:
      return { ...state, workspaceExpiryModalView: action.data };
    case commonTypes.TOGGLE_DOMAIN_RENEWAL_MODAL:
      return { ...state, domainRenewalModalView: action.data };
    case commonTypes.SET_TOP_BANNER_VISIBILITY:
      return {
        ...state,
        topBanner: {
          visible: action.data.visible,
          height: action.data.height || state.topBanner.height,
        },
      };
    case commonTypes.ECOMM_ELITE_CALLBACK_MODAL_DATA: {
      return {
        ...state,
        ecommEliteCallbackModal: {
          ...state.ecommEliteCallbackModal,
          ...action.data,
        },
      };
    }
    case commonTypes.DICE_LOADER_VISIBILTY: {
      return { ...state, diceLoaderVisibilty: action.data };
    }
    case commonTypes.AUTO_GENERATE_TEXT_LOADER: {
      return { ...state, autoGenerateTextLoader: action.data };
    }
    case commonTypes.SAVE_SEARCH_SETTING_DATA: {
      return { ...state, searchedSettingData: action.data };
    }
    case commonTypes.SAVE_SEARCHED_QUERY_ID: {
      return { ...state, saveSearchedQueryId: action.data };
    }
    case commonTypes.SAVE_APP_CONSTANTS:
      return {
        ...state,
        appConstants: action.data || APP_CONSTANTS_DEFAULTS,
      };
    case commonTypes.TOGGLE_THEME_UPSELL_MODAL: {
      return { ...state, themeUpsellModal: action.data };
    }
    case commonTypes.TOGGLE_ORDERS_ONBOARDING_POPUP: {
      return { ...state, ordersOnboardingPopup: action.data };
    }
    default:
      return state;
  }
};

export default commonReducer;
