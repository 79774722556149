import {
  SET_PAGE_LOADER_VISIBILITY,
  SHOW_PREMIUM_SEARCH_MODAL,
  IS_PREMIUM_SET,
  SET_ALERT_FLOW_DIALOG,
  ALERT_MODAL_TOGGLE,
  SAVE_PRODUCT_LOADER_ID,
  SAVE_DETAILED_ITEM_BY_ID,
  SET_CHOOSE_AND_REPEAT_MODEL_VISIBILITY,
  SET_SELECTED_VARIANT_MODEL_VISIBILITY,
  SET_VARIANT_MODEL_VISIBILITY,
  SET_ADD_PRODUCT_MODAL_VISIBLE,
  IS_BILL_CONTACT_MODAL_VISIBLE,
  IS_BILL_SEND_MODAL_VISIBLE,
  IS_CREATE_BILL_MODAL_VISIBLE,
  GET_ACCOUNT_INFO,
  SAVE_USER_ACCOUNT_INFO,
  TOGGLE_SHIPMENT_DATA_MODAL,
  TOGGLE_DELIVERY_TIME_MODAL,
  TOGGLE_CUSTOMER_PAYMENT_CHOICE_MODAL,
  TOGGLE_HOW_TO_SHIP_MODAL,
  PUSH_EVENT_LOG,
  SHOW_DOMAIN_SUCCESSFUL_PURCHASE_MODAL,
  GET_ALL_POLICY_PAGES,
  SAVE_STORE_POLICY_DATA,
  UPDATE_POLICY_PAGES,
  TOGGLE_WORKSPACE_EXPIRY_MODAL,
  TOGGLE_DOMAIN_RENEWAL_MODAL,
  SET_TOP_BANNER_VISIBILITY,
  ECOMM_ELITE_CALLBACK_MODAL_DATA,
  DICE_LOADER_VISIBILTY,
  AUTO_GENERATE_TEXT_LOADER,
  OUT_OF_STOCK,
  UPLOAD_COMPRESSED_IMAGE_TO_S3,
  SEND_DOMAIN_CALLBACK_REQUEST,
  GET_SEARCH_SETTING_DATA,
  SAVE_SEARCH_SETTING_DATA,
  SAVE_SEARCHED_QUERY_ID,
  GET_APP_CONSTANTS,
  SAVE_APP_CONSTANTS,
  TOGGLE_THEME_UPSELL_MODAL,
  TOGGLE_DELIVERY_PARTNER_DRAWER,
  TOGGLE_ORDERS_ONBOARDING_POPUP,
} from "./../ActionTypes";

interface Action {
  type: string;
  data?: any;
  callback?: any;
}

export const setPageLoaderVisibility = (data: boolean): Action => {
  return {
    type: SET_PAGE_LOADER_VISIBILITY,
    data,
  };
};

export const showPremiumSearchModal = (data: boolean): Action => {
  return {
    type: SHOW_PREMIUM_SEARCH_MODAL,
    data,
  };
};

export const isPremiumSet = (data: any): Action => {
  return {
    type: IS_PREMIUM_SET,
    data,
  };
};

export const setAlertFlowDialog = (data: any): Action => {
  return {
    type: SET_ALERT_FLOW_DIALOG,
    data,
  };
};

export const alertModalToggle = (data: any): Action => {
  return {
    type: ALERT_MODAL_TOGGLE,
    data,
  };
};

export const saveProductLoaderId = (data: any): Action => {
  return {
    type: SAVE_PRODUCT_LOADER_ID,
    data,
  };
};

export const saveDetailedItemById = (data: any): Action => {
  return {
    type: SAVE_DETAILED_ITEM_BY_ID,
    data,
  };
};

export const setChooseAndRepeatModalVisibility = (data: any): Action => {
  return {
    type: SET_CHOOSE_AND_REPEAT_MODEL_VISIBILITY,
    data,
  };
};

export const setSelectedVariantModalVisibility = (data: any): Action => {
  return {
    type: SET_SELECTED_VARIANT_MODEL_VISIBILITY,
    data,
  };
};

export const setVariantModalVisibility = (data: any): Action => {
  return {
    type: SET_VARIANT_MODEL_VISIBILITY,
    data,
  };
};

export const setAddProductModalVisible = (data: any): Action => {
  return {
    type: SET_ADD_PRODUCT_MODAL_VISIBLE,
    data,
  };
};

export const setBillContactModalVisible = (data: any): Action => {
  return {
    type: IS_BILL_CONTACT_MODAL_VISIBLE,
    data,
  };
};

export const setBillSendModalVisible = (data: any): Action => {
  return {
    type: IS_BILL_SEND_MODAL_VISIBLE,
    data,
  };
};

export const isCreateBillModalVisible = (data: any): Action => {
  return {
    type: IS_CREATE_BILL_MODAL_VISIBLE,
    data,
  };
};

export const getUserAccountInfo = (): Action => {
  return {
    type: GET_ACCOUNT_INFO,
    data: {},
  };
};

export const saveUserAccountInfo = (data: any): Action => {
  return {
    type: SAVE_USER_ACCOUNT_INFO,
    data,
  };
};

export const toggleShipmentDataModal = (data: boolean): Action => {
  return {
    type: TOGGLE_SHIPMENT_DATA_MODAL,
    data,
  };
};

export const toggleDeliveryTimeModal = (data: boolean): Action => {
  return {
    type: TOGGLE_DELIVERY_TIME_MODAL,
    data,
  };
};

export const toggleCustomerPaymentChoiceModal = (data: boolean): Action => {
  return {
    type: TOGGLE_CUSTOMER_PAYMENT_CHOICE_MODAL,
    data,
  };
};

export const toggleDeliveryPartnerDrawer = (data: boolean): Action => {
  return {
    type: TOGGLE_DELIVERY_PARTNER_DRAWER,
    data,
  };
};

export const toggleHowToShipModal = (data: boolean): Action => {
  return {
    type: TOGGLE_HOW_TO_SHIP_MODAL,
    data,
  };
};

export const pushEventLogAction = (data: any): Action => {
  return {
    type: PUSH_EVENT_LOG,
    data,
  };
};

export const showDomainSuccessfulPurchaseModal = (data = false): Action => {
  return {
    type: SHOW_DOMAIN_SUCCESSFUL_PURCHASE_MODAL,
    data,
  };
};

export const getAllPolicyPages = (callback?: any): Action => {
  return {
    type: GET_ALL_POLICY_PAGES,
    callback,
  };
};

export const saveStorePolicies = (data: any): Action => {
  return {
    type: SAVE_STORE_POLICY_DATA,
    data,
  };
};

export const updateStorePolicyPages = (data: any, callback?: any): Action => {
  return {
    type: UPDATE_POLICY_PAGES,
    data,
    callback,
  };
};

export const setOutOfStockVisiblity = (data: any, callback?: any): Action => {
  return {
    type: OUT_OF_STOCK,
    data,
    callback,
  };
};

export const toggleWorkspaceExpiryModal = (data: any): Action => {
  return {
    type: TOGGLE_WORKSPACE_EXPIRY_MODAL,
    data,
  };
};

export const toggleDomainRenewalModal = (data: any): Action => {
  return {
    type: TOGGLE_DOMAIN_RENEWAL_MODAL,
    data,
  };
};

export const setTopBannerVisibility = (data: { visible: boolean; height?: number }): Action => {
  return {
    type: SET_TOP_BANNER_VISIBILITY,
    data,
  };
};

export const ecommEliteCallbackModalAction = (data: {
  view: boolean;
  planType: null | string;
  featureName?: string;
}): Action => {
  return {
    type: ECOMM_ELITE_CALLBACK_MODAL_DATA,
    data,
  };
};

export const diceLoaderVisibilty = (data: boolean) => {
  return {
    type: DICE_LOADER_VISIBILTY,
    data,
  };
};

export const autoGenerateTextLoader = (data: boolean) => {
  return {
    type: AUTO_GENERATE_TEXT_LOADER,
    data,
  };
};

export const uploadCompressedImage = (data: any, callback?: any): Action => {
  return {
    type: UPLOAD_COMPRESSED_IMAGE_TO_S3,
    data,
    callback,
  };
};

export function sendDomainLandingCallBackRequest(data) {
  return {
    type: SEND_DOMAIN_CALLBACK_REQUEST,
    data,
  };
}

export const getSearchSettingData = (data) => {
  return {
    type: GET_SEARCH_SETTING_DATA,
    data,
  };
};

export const saveSearchSettingData = (data) => {
  return {
    type: SAVE_SEARCH_SETTING_DATA,
    data,
  };
};

export const saveSearchedQuery = (data) => {
  return {
    type: SAVE_SEARCHED_QUERY_ID,
    data,
  };
};

export function getAppConstants(isDLPCall) {
  return {
    type: GET_APP_CONSTANTS,
    isDLPCall,
  };
}

export function saveAppConstants(data) {
  return {
    type: SAVE_APP_CONSTANTS,
    data,
  };
}

export const toggleThemeUpsellModal = (data) => {
  return {
    type: TOGGLE_THEME_UPSELL_MODAL,
    data,
  };
};

export const toggleOrderOnboardingPopup = (data) => {
  return {
    type: TOGGLE_ORDERS_ONBOARDING_POPUP,
    data,
  };
};
