import { ANDROID_UPI_INTENT_LINK } from "./../../Config/paymentLinks";
import { cancelled, put, takeEvery } from "redux-saga/effects";
import apiCall from "./../../Services/api";
import config from "./../../Config";
import * as paymentTypes from "./../ActionTypes";
import { ToastNotifyError, ToastNotifySuccess } from "../../Components/Toast";
import {
  setPageLoaderVisibility,
  savePostPaymentData,
  isPremiumSet,
  savePaymentModesData,
  paymentOptionsChange,
  paymentMethodChange,
  setActivePaymentMethods,
  toggleLazyPayAuth,
  fetchSubscriptionDetails,
  authenticateUser,
} from "../Actions";
import { saveEmiOptionsSpecificToCard, saveAllEmiOptions } from "../Actions/payment";
import store from "../Store";
import history from "../../Utils/history";
import { getPurchaseTypeFromText, parseToJson } from "./../../Utils/common";
import { LAZYPAY_VIEWS, PAYMENT_METHOD_TYPES, PREMIUM_PURCHASE_TYPE_CONSTANTS, TRANSACTION_ID } from "../../Constants";
import { getPremiumPurchaseType, redirectFromPaymentPage } from "../../Utils/_helper";
import { CREDIT_ENGINE_ROUTE, ORDERS_PAGE_ROUTE, SUBSCRIPTION_STATUS_DETAILS } from "../../Constants/routesConstant";
import {
  CREDIT_ENGINE_API,
  MDR_FLAG_API,
  SET_EMI_FLAG,
  PAYMENT_API,
  EMI_API,
  GST_BILLING,
} from "../../Services/apiUrls";
import LocalStorageHelper from "../../Utils/LocalStorageHelper";
import { getAuthToken } from "../../Utils/authToken";
/**
 * Payment initialization
 * for QR
 * @param {*} actions
 */
function* initPaymentForQR(actions: any): Generator<any, any, any> {
  try {
    yield put(setPageLoaderVisibility(true));

    const paymentResponse = yield apiCall({
      method: "POST",
      url: PAYMENT_API.initiatePayment,
      data: actions.data,
      parseToJson: true,
    });

    if (paymentResponse?.data?.status) {
      yield put(setPageLoaderVisibility(false));

      const response = paymentResponse.data?.data;

      // Creating a Intent Link
      const intentLink = ANDROID_UPI_INTENT_LINK.replace("{deeplink}", response.data.url);
      sessionStorage.setItem(TRANSACTION_ID, response.txnId);

      // Send data back from where payment initiated
      actions.callback &&
        actions.callback({
          status: true,
          intentLink,
        });
    } else {
      yield put(setPageLoaderVisibility(false));

      // Send data back from where payment initiated
      actions.callback &&
        actions.callback({
          status: false,
        });
    }
  } catch (err) {
    yield put(setPageLoaderVisibility(false));
    ToastNotifyError("Something went bad! try again later.");
  }
}

// payment polling in case of UPI
function* pullPayment(actions: any): Generator<any, any, any> {
  try {
    const pullPaymentResponse = yield apiCall({
      method: "GET",
      url: `${config.base_url}${PAYMENT_API.pullPaymentStatus(actions.data)}`,
    });
    if (pullPaymentResponse?.data?.status) {
      const premiumPurchaseTypeIsTheme = getPurchaseTypeFromText(getPremiumPurchaseType());
      const store_id = store.getState().storeReducer.store.store_id;
      const prePaymentData = store.getState().paymentMethodReducer.prePaymentData;
      const campaignId = store.getState().smsMarketingReducer.campaign_id;
      sessionStorage.removeItem(TRANSACTION_ID);
      if (getPremiumPurchaseType() === PREMIUM_PURCHASE_TYPE_CONSTANTS.CREDIT_ENGINE) {
        ToastNotifySuccess("Credits successfully added!");
        history.push(CREDIT_ENGINE_ROUTE, { from: "/payment", firstTimePaymentSuccessful: true });
      } else {
        yield put(
          isPremiumSet({
            store_id: Number(store_id),
            type_id: prePaymentData.theme_id,
            type: premiumPurchaseTypeIsTheme,
            apply_type: "paid",
            text: prePaymentData?.domain,
          }),
        );
      }
      yield put(setPageLoaderVisibility(false));
    } else {
      ToastNotifyError("Payment failed. Please try again!");
      actions.callback?.(false);
      yield put(setPageLoaderVisibility(false));
      sessionStorage.removeItem(TRANSACTION_ID);
    }
  } catch (err) {
    yield put(setPageLoaderVisibility(false));
  }
}

/**
 * Payment initialization
 * for card
 * @param {*} actions
 */
function* initiatePayment(actions: any): Generator<any, any, any> {
  try {
    yield put(setPageLoaderVisibility(true));
    const paymentResponse = yield apiCall({
      method: "POST",
      url: PAYMENT_API.initiatePayment,
      data: actions.data,
      parseToJson: true,
    });

    if (paymentResponse?.data?.status) {
      yield put(savePostPaymentData(paymentResponse.data?.data));
      history.push(`/payment/processing/${paymentResponse.data?.data?.txnId}`);
    } else {
      ToastNotifyError("Something went bad! Try again later.");
    }
  } catch (err) {
    ToastNotifyError("Something went bad! Try again later.");
  } finally {
    yield put(setPageLoaderVisibility(false));
  }
}

// payment polling in case of Card
function* verifyPayment(actions: any): Generator<any, any, any> {
  try {
    // const paymentMethod = store.getState().paymentMethodReducer.paymentInfo.paymentMethod;
    const paymentUrl =
      actions.data.method === PAYMENT_METHOD_TYPES.AIRTEL
        ? PAYMENT_API.verifyPaymentForAirtel(actions.data.txnId)
        : PAYMENT_API.verifyPayment(actions.data.txnId);

    const pullPaymentResponse = yield apiCall({
      method: "GET",
      url: `${config.base_url}${paymentUrl}`,
      parseToJson: true,
    });
    if (pullPaymentResponse?.data?.status) {
      const premiumPurchaseTypeIsTheme = getPurchaseTypeFromText(getPremiumPurchaseType());
      const store_id = store.getState().storeReducer.store.store_id;
      const prePaymentData = store.getState().paymentMethodReducer.prePaymentData;
      const campaignId = store.getState().smsMarketingReducer.campaign_id;
      LocalStorageHelper.delete(TRANSACTION_ID);
      yield put(savePostPaymentData({}));
      history.push(SUBSCRIPTION_STATUS_DETAILS);
      yield put(
        isPremiumSet({
          store_id: Number(store_id),
          type_id: prePaymentData.theme_id,
          type: premiumPurchaseTypeIsTheme,
          apply_type: "paid",
          text: prePaymentData?.domain,
        }),
      );
    } else {
      LocalStorageHelper.delete(TRANSACTION_ID);
      yield put(savePostPaymentData({}));
      history.push(ORDERS_PAGE_ROUTE);
      ToastNotifyError(
        "Payment failed. If any amount is deducted, it will be refunded by bank within 5-7 business days.",
      );
    }
    yield put(setPageLoaderVisibility(false));
    sessionStorage.removeItem(TRANSACTION_ID);
  } catch (err) {
    yield put(setPageLoaderVisibility(false));
  }
}

// get payment Modes
function* fetchPaymentModes(actions: {
  type: string;
  data: { store_id: number };
  callback: any;
}): Generator<any, any, any> {
  try {
    const payload = {
      store_id: actions.data.store_id,
    };
    yield put(setPageLoaderVisibility(true));
    const getPaymentModesResponse = yield apiCall({
      method: "GET",
      data: payload,
      url: "/dotk/vm1/settings/getPaymentModesPageInfo",
    });
    if (getPaymentModesResponse?.status) {
      yield put(savePaymentModesData(JSON.parse(getPaymentModesResponse.data.data) || {}));
      yield put(paymentOptionsChange(false));
      yield put(setPageLoaderVisibility(false));
    }
  } catch (err) {
    yield put(setPageLoaderVisibility(false));
    ToastNotifyError("Unable to fetch payment modes");
  }
}

// set payment options
function* setPaymentOptions(actions: {
  type: string;
  data: { store_id: number; status: boolean; payment_type: string };
  callback: any;
}): Generator<any, any, any> {
  try {
    const payload = {
      store_id: actions.data.store_id,
      status: actions.data.status ? 1 : 0,
      payment_type: actions.data.payment_type,
    };
    yield put(setPageLoaderVisibility(true));
    const setPaymentOptionsResponse = yield apiCall({
      method: "POST",
      data: payload,
      headers: {
        app_version: 1,
      },
      url: "/dotk/vm1/settings/setPaymentOptions",
    });
    if (setPaymentOptionsResponse?.data.status) {
      yield put(paymentOptionsChange(setPaymentOptionsResponse.data.status || false));
      yield put(setPageLoaderVisibility(false));
      actions.callback ? actions.callback(true) : ToastNotifySuccess("Payment status updated successfully!");
    }
  } catch (err) {
    yield put(setPageLoaderVisibility(false));
    if (err?.data?.message) {
      ToastNotifyError(err.data.message);
    } else {
      ToastNotifyError("Unable to update payment status");
    }
    actions?.callback(false);
  }
}

// update payment methods
function* updatePaymentMethod(actions: any): Generator<any, any, any> {
  try {
    const store_id = store.getState().storeReducer.store.store_id;
    const payload = {
      store_id: store_id,
      flag: actions.data.payment_method,
    };
    yield put(setPageLoaderVisibility(true));
    const updatePaymentMethodResponse = yield apiCall({
      method: "POST",
      data: payload,
      url: "/dotk/vm1/settings/updatePaymentMethod",
    });
    if (updatePaymentMethodResponse?.data?.status) {
      yield put(paymentMethodChange(updatePaymentMethodResponse.data.status || false));
      yield put(setPageLoaderVisibility(false));
      if (actions?.data?.callback) {
        actions?.data?.callback();
      }
    }
  } catch (err) {
    yield put(setPageLoaderVisibility(false));
    if (err?.data?.message) {
      ToastNotifyError(err.data.message);
    } else {
      ToastNotifyError("Unable to update payment method");
    }
  }
}

function* getPaymentModesByMerchantId(actions: any): Generator<any, any, any> {
  try {
    const paymentMethodResponse = yield apiCall({
      method: "POST",
      data: actions.data,
      url: "/dotk/vo1/paymentOptions/getPaymentModesByMerchantId",
    });
    if (paymentMethodResponse?.data?.status) {
      const parsedData = parseToJson(paymentMethodResponse?.data?.data);
      yield put(setActivePaymentMethods(parsedData?.payment_modes));
    }
  } catch (err) {
    console.log(err);
  }
}

function* initLazyPayPayment(actions: any): Generator<any, any, any> {
  try {
    const paymentResponse = yield apiCall({
      method: "POST",
      url: PAYMENT_API.initiateLazyPayPayment,
      data: actions.data,
    });

    if (paymentResponse && paymentResponse.data.status) {
      const response = parseToJson(paymentResponse.data.data);
      sessionStorage.setItem(TRANSACTION_ID, response.txnID);
      console.log("Initiate LazyPay Payment", response);
      if (response.is_eligible) {
        if (response.is_otp_required) {
          yield put(
            toggleLazyPayAuth({
              show: true,
              view: LAZYPAY_VIEWS.OTP,
            }),
          );
        } else if (response.type === "autoDebit") {
          const premiumPurchaseTypeIsTheme = getPurchaseTypeFromText(getPremiumPurchaseType());
          const store_id = store.getState().storeReducer.store.store_id;
          const prePaymentData = store.getState().paymentMethodReducer.prePaymentData;
          yield put(
            isPremiumSet({
              store_id: Number(store_id),
              type_id: prePaymentData.theme_id,
              type: premiumPurchaseTypeIsTheme,
              apply_type: "paid",
              text: prePaymentData?.domain,
            }),
          );
          sessionStorage.removeItem(TRANSACTION_ID);
        } else {
          yield put(
            toggleLazyPayAuth({
              show: true,
              // view: LAZYPAY_VIEWS.ELIGIBLE,
              view: LAZYPAY_VIEWS.NONELIGIBLE,
            }),
          );
        }
      } else {
        yield put(
          toggleLazyPayAuth({
            show: true,
            view: LAZYPAY_VIEWS.NONELIGIBLE,
          }),
        );
      }
    }
  } catch (err: unknown) {
    console.log(err);
  }
}

function* verifyLazyPayOtp(actions: any): Generator<any, any, any> {
  try {
    const verifyResponse = yield apiCall({
      method: "POST",
      url: PAYMENT_API.verifyLazyPayOtp,
      data: actions.data,
    });

    if (verifyResponse && verifyResponse.data.status === "success") {
      const premiumPurchaseTypeIsTheme = getPurchaseTypeFromText(getPremiumPurchaseType());
      const store_id = store.getState().storeReducer.store.store_id;
      const prePaymentData = store.getState().paymentMethodReducer.prePaymentData;
      yield put(
        isPremiumSet({
          store_id: Number(store_id),
          type_id: prePaymentData.theme_id,
          type: premiumPurchaseTypeIsTheme,
          apply_type: "paid",
          text: prePaymentData?.domain,
        }),
      );
      sessionStorage.removeItem(TRANSACTION_ID);
      redirectFromPaymentPage(premiumPurchaseTypeIsTheme);
    } else if (verifyResponse && verifyResponse.data.status === "failure") {
      ToastNotifyError("Invalid OTP");
    }
  } catch (error: unknown) {
    console.log(error);
  }
}

/**
 * This will be called when all the payment has been done from credits
 * @param actions
 */
function* completePaymentFromCredits(actions: any): Generator<any, any, any> {
  try {
    yield put(setPageLoaderVisibility(true));
    const completePaymentResponse = yield apiCall({
      method: "POST",
      url: `${config.base_url}${CREDIT_ENGINE_API.completePaymentApi}`,
      data: actions.data,
    });
    const prePaymentData = store.getState().paymentMethodReducer.prePaymentData;
    const campaignId = store.getState().smsMarketingReducer.campaign_id;
    if (completePaymentResponse && completePaymentResponse.data.status) {
      yield put(setPageLoaderVisibility(false));
      const premiumPurchaseTypeIsTheme = getPurchaseTypeFromText(getPremiumPurchaseType());
      sessionStorage.removeItem(TRANSACTION_ID);
      redirectFromPaymentPage(premiumPurchaseTypeIsTheme);
      yield put(fetchSubscriptionDetails());
      yield put(authenticateUser(getAuthToken()));
    } else {
      yield put(setPageLoaderVisibility(false));
      sessionStorage.removeItem(TRANSACTION_ID);
      ToastNotifyError(completePaymentResponse.data.message);
    }
  } catch (error: unknown) {
    console.log(error);
  }
}

function* setMdrFlag(actions: any): Generator<any, any, any> {
  try {
    const response = yield apiCall({
      method: "PUT",
      url: `${config.base_url}${MDR_FLAG_API}`,
      data: {
        flag: actions.data,
      },
    });
    actions?.callback?.(response?.data?.status || false);
  } catch (err) {
    console.log(err);
    actions?.callback?.(false);
  }
}

function* toggleEmiFlag(actions: any): Generator<any, any, any> {
  try {
    const response = yield apiCall({
      method: "PUT",
      url: `${config.base_url}${SET_EMI_FLAG}`,
      data: {
        flag: actions.data,
      },
    });
    actions?.callback?.(response?.data?.status ?? false);
  } catch (err) {
    console.log(err);
    actions?.callback?.(false);
  }
}

function* setPartialPaymentModeData(actions: any) {
  try {
    const response = yield apiCall({
      method: "PUT",
      url: `${config.base_url}/dotk/vm1/settings/partialPaymentConfig`,
      data: actions.data,
    });
    if (response?.data?.status) {
      actions?.callback?.();
    }
  } catch (err) {
    console.log(err);
    actions?.callback?.(false);
  }
}

// EMI Options

function* getEmiOptionsSpecificToCard(actions) {
  try {
    const response = yield apiCall({
      method: "POST",
      url: EMI_API.GET_EMI_OPTIONS_FOR_CARD,
      data: actions.data,
    });
    if (response?.data?.status) {
      const parsedData = JSON.parse(response?.data?.data);
      yield put(saveEmiOptionsSpecificToCard(parsedData?.emi_options ?? {}));
      actions?.callback?.(!!parsedData?.emi_options?.length);
    }
  } catch (err) {
    yield put(saveEmiOptionsSpecificToCard({}));
    actions?.callback?.(false);
    console.log(err);
  }
}

function* getAllEmiOptions(actions) {
  try {
    const response = yield apiCall({
      method: "POST",
      url: EMI_API.GET_ALL_EMI_OPTIONS,
      data: actions.data,
    });
    if (response?.data?.status) {
      const parsedData = JSON.parse(response?.data?.data);
      yield put(saveAllEmiOptions(parsedData));
    }
  } catch (err) {
    console.log(err);
  }
}

function* setEmiOption(actions) {
  try {
    const response = yield apiCall({
      method: "PUT",
      url: EMI_API.SET_EMI_OPTION,
      data: actions.data,
    });
    actions?.callback(response?.data?.status);
  } catch (err) {
    actions?.callback(false);
  }
}

function* verifyGstForPurchase(actions: any) {
  yield put(setPageLoaderVisibility(true));
  try {
    const response = yield apiCall({
      method: "POST",
      data: actions.data,
      url: config.base_url + GST_BILLING.verifyGSTForPurchase,
    });
    if (response && response?.data?.status) {
      actions.callback(true, response?.data?.text || "");
    } else {
      actions.callback(false, "");
    }
    yield put(setPageLoaderVisibility(false));
  } catch (err) {
    yield put(setPageLoaderVisibility(false));
    actions.callback(false, "");
  }
}

export default function* root() {
  yield takeEvery(paymentTypes.INIT_PAYMENT_FOR_QR, initPaymentForQR);
  yield takeEvery(paymentTypes.PULL_PAYMENT_STATUS, pullPayment);
  yield takeEvery(paymentTypes.INIT_PAYMENT, initiatePayment);
  yield takeEvery(paymentTypes.VERIFY_PAYMENT, verifyPayment);
  yield takeEvery(paymentTypes.FETCH_PAYMENT_MODES, fetchPaymentModes);
  yield takeEvery(paymentTypes.SET_PAYMENT_OPTIONS, setPaymentOptions);
  yield takeEvery(paymentTypes.UPDATE_PAYMENT_METHOD, updatePaymentMethod);
  yield takeEvery(paymentTypes.GET_PAYMENT_MODES_BY_MERCHANT_ID, getPaymentModesByMerchantId);
  yield takeEvery(paymentTypes.INIT_LAZY_PAY_PAYMENT, initLazyPayPayment);
  yield takeEvery(paymentTypes.VERIFY_LAZY_PAY_OTP, verifyLazyPayOtp);
  yield takeEvery(paymentTypes.COMPLETE_PAYMENT_ACTION, completePaymentFromCredits);
  yield takeEvery(paymentTypes.SET_MDR_FLAG, setMdrFlag);
  yield takeEvery(paymentTypes.TOGGLE_EMI_FLAG, toggleEmiFlag);
  yield takeEvery(paymentTypes.SET_PARTIAL_PAYMENT_DATA, setPartialPaymentModeData);
  yield takeEvery(paymentTypes.GET_EMI_OPTIONS_FOR_CARD, getEmiOptionsSpecificToCard);
  yield takeEvery(paymentTypes.GET_ALL_EMI_OPTIONS, getAllEmiOptions);
  yield takeEvery(paymentTypes.SET_EMI_OPTION, setEmiOption);
  yield takeEvery(paymentTypes.VERIFY_GST_FOR_PURCHASE, verifyGstForPurchase);
}
