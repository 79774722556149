import _ from "lodash";
import { stringSet, alphabetsStringSet, COUPONS_BROWSE_TYPES, STRING_NUMBER_TYPE, COUPON_TYPES, COUPONS_APPLICABILITY_TYPES, COUPONS_SORT_BY, COUPONS_STATUSES, COUPONS_SORT_TYPE, AUTO_GENERATED_COUPON_CODE_LENGTH, COUPONS_VOUCHERS_DEFAULT_DATA, TIME_FORMAT, DATE_FORMAT } from "@/Constants/couponsVouchersConstants";
import { ICouponsVouchersData, IPromoApplicability } from "@/Types/couponsVouchersTypes";
import store from "@/Redux/Store";
import { fetchCouponListV2 } from "@/Redux/Actions";
import moment from "moment";

export function getCouponCode() {
  let code = "";
  for (let i = 0; i < AUTO_GENERATED_COUPON_CODE_LENGTH; i++) {
    let supersetString = "";
    if (i < 4) {
      // first n letters should be alphabets
      supersetString = alphabetsStringSet;
    } else {
      supersetString = stringSet;
    }
    code += supersetString[Math.floor(Math.random() * supersetString.length)];
  }

  return code;
}

export function getDefaultCouponCreationData() {
  const data = COUPONS_VOUCHERS_DEFAULT_DATA;
  data.start_date = moment().format(DATE_FORMAT);
  data.start_time = moment().format(TIME_FORMAT);
  return data;
}

export function convertToCompatibleObject(items, type, isSearch = false) {
  const allItems = [];

  // as we are getting items from a separate API for products and (category, tags, collections)
  if (isSearch) {
    (items || []).forEach((item) => {
      allItems.push({
        type_id: item?.id,
        name: item?.name,
        description: item?.category?.name,
        image: item?.image_url,
        type: getApplicabilityTypeFromProductBrowseType(parseInt(type)),
      });
    });
  } else {
    return items;
  }

  return allItems;
}

export function convertItemIdsToMapOfItems(arr = [], type) {
  const map = {};
  (arr || []).forEach((ele) => {
    map[ele] = {type_id: ele, type: getApplicabilityTypeFromProductBrowseType(parseInt(type))};
  });

  return map;
}

export function convertItemsArrayToMap(arr = []) {
  const map = {};
  (arr || []).forEach((ele) => {
    map[ele.type_id] = ele;
  });

  return map;
}

export function getBrowseTypeFromProductsAdded(products: IPromoApplicability[]) {
  if (products?.length <= 0) {
    return COUPONS_BROWSE_TYPES.TYPE_PRODUCT; // considering product as default selection
  }
  const productType = products?.[0]?.type;
  if (productType === COUPONS_APPLICABILITY_TYPES.TYPE_PRODUCT) {
    return COUPONS_BROWSE_TYPES.TYPE_PRODUCT;
  } else if (productType === COUPONS_APPLICABILITY_TYPES.TYPE_CATEGORY) {
    return COUPONS_BROWSE_TYPES.TYPE_CATEGORY;
  } else if (productType === COUPONS_APPLICABILITY_TYPES.TYPE_COLLECTION) {
    return COUPONS_BROWSE_TYPES.TYPE_COLLECTION;
  } else if (productType === COUPONS_APPLICABILITY_TYPES.TYPE_TAG) {
    return COUPONS_BROWSE_TYPES.TYPE_TAG;
  }
  return COUPONS_BROWSE_TYPES.TYPE_PRODUCT;
}

export function getCouponTypeByProductAdded(products: IPromoApplicability[]) {
  if (products?.length <= 0) {
    return COUPON_TYPES.PRODUCT; // considering product as default selection
  }
  const productType = products?.[0]?.type;
  if (productType === COUPONS_APPLICABILITY_TYPES.TYPE_PRODUCT) {
    return COUPON_TYPES.PRODUCT;
  } else if (productType === COUPONS_APPLICABILITY_TYPES.TYPE_CATEGORY) {
    return COUPON_TYPES.CATEGORY;
  } else if (productType === COUPONS_APPLICABILITY_TYPES.TYPE_COLLECTION) {
    return COUPON_TYPES.COLLECTION;
  } else if (productType === COUPONS_APPLICABILITY_TYPES.TYPE_TAG) {
    return COUPON_TYPES.TAG;
  }

  return productType;
}

export function getApplicabilityTypeFromProductBrowseType (productBrowseType: COUPONS_BROWSE_TYPES) {
  if (productBrowseType === COUPONS_BROWSE_TYPES.TYPE_CATEGORY) {
    return COUPONS_APPLICABILITY_TYPES.TYPE_CATEGORY;
  } else if (productBrowseType === COUPONS_BROWSE_TYPES.TYPE_COLLECTION) {
    return COUPONS_APPLICABILITY_TYPES.TYPE_COLLECTION;
  } else if (productBrowseType === COUPONS_BROWSE_TYPES.TYPE_PRODUCT) {
    return COUPONS_APPLICABILITY_TYPES.TYPE_PRODUCT;
  } else if (productBrowseType === COUPONS_BROWSE_TYPES.TYPE_TAG) {
    return COUPONS_APPLICABILITY_TYPES.TYPE_TAG;
  }
}

// cv means coupons and vouchers
export function cvDataTranslator(cvData: ICouponsVouchersData, extraFields: {
  fromBEToFE: boolean;
}): ICouponsVouchersData {
  const {fromBEToFE} = extraFields;

  // this translation can be ignored if BE starts sending data with synced names. (check desc of this function).
  cvData.customer_group_list = translateCustomerGroupList(cvData.customer_group_list);

  return {
    code: cvData.code,
    status: cvData.status,
    discounting_type: conversionStrinInt(fromBEToFE, cvData.discounting_type),
    discount: conversionStrinInt(fromBEToFE, cvData.discount),
    max_discount: conversionStrinInt(fromBEToFE, cvData.max_discount),
    min_order_value: conversionStrinInt(fromBEToFE, cvData.min_order_value),
    min_item_quantity: conversionStrinInt(fromBEToFE, cvData.min_item_quantity),
    reward_item_quantity: conversionStrinInt(fromBEToFE, cvData.reward_item_quantity),
    start_date: cvData.start_date,
    end_date: cvData.end_date,
    start_time: cvData.start_time,
    end_time: cvData.end_time,
    is_visible_on_website: conversionStrinInt(fromBEToFE, cvData.is_visible_on_website, true),
    added_as_hello_bar: conversionStrinInt(fromBEToFE, cvData.added_as_hello_bar, true),
    order_type: conversionStrinInt(fromBEToFE, cvData.order_type),
    max_usage: conversionStrinInt(fromBEToFE, cvData.max_usage, true),
    use_count: conversionStrinInt(fromBEToFE, cvData.use_count),
    customer_group_list: cvData.customer_group_list,
    promo_applicability: fromBEToFE
      ? convertToApplicabilityTypeObjectsArray(cvData.promo_applicability_ids, cvData.promo_applicability)
      : cvData.promo_applicability,
    promo_reward_applicability: fromBEToFE
    ? convertToApplicabilityTypeObjectsArray(cvData.promo_reward_applicability_ids, cvData.promo_reward_applicability)
    : cvData.promo_reward_applicability,
    id: cvData.id,
    updated_date: cvData.updated_date,
    updated_time: cvData.updated_time,
  };
}

/**
 * this converts an object of
 * {
 *  type: 1,
 *  type_ids: [11, 22]
 * }
 * to [{type: 1, type_id: 11}, {type: 1, type_id: 22}]
 *
 * then this merges the generated array with 4-5 prefilled array being received from backend
 * such that prefilled array is visible on form's main screen
 *
 */
function convertToApplicabilityTypeObjectsArray(idsObject, filledApplicability=[]) {
  const duplicateRemoverObj = {};

  const type = idsObject?.type;
  const typeIDs = idsObject?.type_ids || [];

  typeIDs.forEach((ele) => {
    duplicateRemoverObj[ele] = {
      type: type,
      type_id: ele,
    };
  });

  filledApplicability?.forEach((ele) => {
    duplicateRemoverObj[ele?.type_id] = ele;
  });

  const mergedNonDuplicateArray = Object.values(duplicateRemoverObj);

  return bringFilledApplicabilityObjectsToStart(mergedNonDuplicateArray);
}

function conversionStrinInt(fromBEToFE: boolean, value: string | number, isBoolean = false): STRING_NUMBER_TYPE | number {
  return fromBEToFE ? String(isBoolean ? value : value || "") as STRING_NUMBER_TYPE : parseFloat(value as string);
}

/**
 * This function is written as there is some un-uniformity between data returned from
 * marketing/getPromoCustomerGroupLists and data that is expected for creating coupons
 *
 * as for creating coupons customer_group_type, customer_group_id is required
 * but we get type, type_id from above mentioned API
 *
 * @param data 
 * @returns 
 */
function translateCustomerGroupList(data: any[]): any[] {
  data = data?.map((group) => {
    if (group?.customer_group_type || group?.customer_group_id) {
      return group;
    }
    return {
      customer_group_type: group?.type,
      customer_group_id: group?.type_id,
    };
  });
  return data;
}

export function getSearchResults(allItems: any[], getSearchResults: string) {
  return (allItems || []).filter((item) => item?.name?.toLowerCase()?.indexOf(getSearchResults?.toLowerCase()) > -1);
}

export function bringFilledApplicabilityObjectsToStart(applicabilityObj: any[]) {
  const filled = [];
  const nonFilled = [];

  applicabilityObj.forEach((ele: any) => {
    if (ele?.name) {
      filled.push(ele);
    } else {
      nonFilled.push(ele);
    }
  });

  return [...filled, ...nonFilled];
}

export function initCallForTableData(callback?: () => void) {
  const dispatch = store.dispatch;
  const storeId = store.getState().storeReducer.store.store_id;
  dispatch(
      fetchCouponListV2(
        {
          page: 1,
          sort_by: COUPONS_SORT_BY.CREATED_AT,
          status: COUPONS_STATUSES.ALL,
          sort_type: COUPONS_SORT_TYPE.SORT_TYPE_ASC,
          store_id: storeId,
        },
        callback,
      ),
    );

}
